import { graphql, useStaticQuery } from "gatsby"

function useSiteMetaData() {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  return data.site.siteMetadata
}

export default useSiteMetaData
