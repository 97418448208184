/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import useSiteMetaData from "../hooks/useSiteMetaData"

function Seo({ description, title }) {
  const {
    title: defaultTitle,
    description: defaultDescription,
    author,
  } = useSiteMetaData()

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    author: author,
  }

  const metaTags = [
    {
      name: `description`,
      content: seo.description,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: seo.author || ``,
    },
    {
      name: `twitter:title`,
      content: seo.title,
    },
    {
      name: `twitter:description`,
      content: seo.description,
    },
  ]

  const metaProperties = [
    {
      property: `og:title`,
      content: seo.title,
    },
    {
      property: `og:description`,
      content: seo.description,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:url`,
      content: `https://slickbluedragon.co.uk`,
    },
  ]

  return (
    <>
      <title>{seo.title}</title>
      <meta name="lang" content="en" />
      {metaTags.map((item,index) => {
        return <meta key={index} name={item.name} content={item.content} />
      })}
      {metaProperties.map((item, index ) => {
        return <meta key={index} property={item.property} content={item.content} />
      })}
    </>
  )
}

export default Seo
